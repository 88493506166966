html,
body {
  max-width: 100%;
  height: 100%;
  margin: 0;
  background-color: #f3f3f3;
  -webkit-text-size-adjust: none;
  /* -webkit-text-size-adjust: 100%; */
}

#root {
  height: 100%;
}

div {
  font-family: "Poppins", "Cairo";
}

/* p {
  color: #07303e;
} */

li::marker {
  color: #07303e;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important; /* for chrome */
  filter: none; /* for mozilla */
}

.file-input {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
  cursor: pointer;
}

.slick-arrow {
  color: black !important;
}
.slick-prev:before {
  display: list-item !important;
  opacity: 1 !important;
  color: black !important;
  content: "";
  background-image: url("../icons/arrowLeft.svg");
  background-color: black;
  color: rgba(0, 0, 0, 0) !important;
  font-weight: 1000;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  z-index: 2000;
}
.slick-next:before {
  display: list-item !important;
  color: black !important;
  content: "";
  background-size: 20px 20px;
  background-image: url("../icons/arrowRight.svg");
  background-color: black;
  color: rgba(0, 0, 0, 0) !important;
  background-repeat: no-repeat;
  font-weight: 1000;
  opacity: 1 !important;
}
button.slick-arrow.slick-next {
  height: 100%;
  background: black;
  opacity: 0.5;
  left: calc(98.86% - 12px);
}
button.slick-arrow.slick-prev {
  height: 100%;
  background: black;
  opacity: 0.5;
  left: 0;
  z-index: 1;
}
div.slick-slide > div > div {
  display: block !important;
}
.myimg {
  height: 100;
  object-fit: cover;
  width: 94.3%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0.5;
}
.slick-slide.slick-active.slick-current > div > div > img {
  filter: brightness(1);
}
.slick-slide div {
  outline: none;
}

/* .file-input + label {
  color: rgba(0, 0, 0, 0.87);
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Cairo', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
}
   */
/*.file-input:focus + label,
.file-input + label:hover {
    background-color: #f15d22;
}
  
.file-input:focus + label {
  outline: 1px dotted #000;
} */

/* * {
  -ms-overflow-style: none;
}
*::-webkit-scrollbar {
  display: none;
} */

/* /////////////////////////////////////////////// */

#tooltip {
  position: absolute;
  left: 0px;
  top: 0px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;

  display: inline-block;
}

#tooltip.right {
  margin-left: 5px;
}

#tooltip.left {
  margin-left: -5px;
}

#tooltip.top {
  margin-top: -5px;
}

#tooltip.bottom {
  margin-top: 5px;
}

#tooltip .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #fff;
}

#tooltip.right .tooltip-arrow {
  top: 50%;
  left: auto;
  margin-left: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #fff;
}

#tooltip.right-top .tooltip-arrow {
  top: 10%;
  left: auto;
  margin-left: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #fff;
}

#tooltip.right-bottom .tooltip-arrow {
  top: 90%;
  left: auto;
  margin-left: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #fff;
}

#tooltip.top .tooltip-arrow {
  top: auto;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #fff;
}

#tooltip.top-right .tooltip-arrow {
  top: auto;
  bottom: -5px;
  left: 90%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #fff;
}

#tooltip.top-left .tooltip-arrow {
  top: auto;
  bottom: -5px;
  left: 10%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #fff;
}

#tooltip.left .tooltip-arrow {
  top: 50%;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #fff;
  right: -5px;
  left: auto;
}

#tooltip.left-top .tooltip-arrow {
  top: 10%;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #fff;
  right: 5px;
  left: auto;
}

#tooltip.left-bottom .tooltip-arrow {
  top: 90%;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #fff;
  right: 5px;
  left: auto;
}

#tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #fff;
}

#tooltip.bottom-left .tooltip-arrow {
  top: 0;
  left: 10%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #fff;
}

#tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 90%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #fff;
}

#tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

#tooltip .tooltip-label {
  max-width: 400px;
  width: 220px;
  height: 105px;
  padding: 4px 8px;
  line-height: 18px;
  color: #000;
  text-align: start;
  background-color: #fff;
  border-radius: 4px;
}

.PhoneInputInput {
  height: 32px;
  /* margin-bottom: 15px; */
  /* border-color: white;
  border-radius: 5px; */
  font-size: 16px;
  padding-left: 6px;
  padding-left: 6px;
  border: 0;
  border-radius: 0px 5px 5px 0px;
  border: solid;
  border-width: 1px 1px 1px 0;
  border-color: #c4c4c4;
}

.PhoneInputCountry {
  /* margin-bottom: 15px; */
  pointer-events: none;
  background: white;
  padding-left: 10px;
  margin-right: -4px !important;
  border-radius: 5px 0px 0px 5px;
  border: solid;
  border-width: 1px 0px 1px 1px;
  border-color: #c4c4c4;
  margin-right: 0;
}

.PhoneInputCountrySelectArrow {
  display: none !important;
}

.PhoneInputCountryIcon--border {
  margin-right: 9px;
}

.PhoneInput {
  direction: ltr;
  margin-bottom: 15px;
  /* box-shadow: 0px 4px 10px rgb(7 48 62 / 10%); */
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 2.20309px 2.67182px rgb(130 148 154 / 40%);
}
input:focus-visible {
  outline: none;
}

#webLoginField,
#webresetField {
  /* height: 46px; */
  height: 40px;
}

#mobileresetField {
  height: 36px !important;
}

#signupPhone {
  height: 48px;
  margin-bottom: 0;
  /* border-radius: 0 10.67px 10.67px 0; */
  border-radius: 0 5px 5px 0;
}

.correct {
  border: 1px solid #37c534 !important;
  box-shadow: 0px 0px 1px #37c534;
  margin: 0;
}
